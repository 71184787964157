

































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import WithMenuTagChip from '@/ui/components/atoms/groupingItems/WithMenuTagChip.vue';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import TagAutocomplete from '@/ui/components/molecules/inputs/TagAutocomplete.vue';

import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';
import type { Campaign } from '@/core/campaign/models/Campaign';
import i18n from '@/i18n';
import { TagType } from '@/core/tags/enums/TagType';
import type { Tag } from '@/core/tags/models/Tag';
import getLogger from '@/shared/logger/logger';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('CampaignBaseInfo');

@Component({
  name: 'CampaignBaseInfo',
  components: { WithMenuTagChip, TagAutocomplete },
})
export default class CampaignBaseInfo extends Vue {
  @Prop() private campaign!: Campaign;
  protected tagType = TagType.GENERAL;
  protected searchResults: Tag[] = [];
  protected title = '';
  protected description = '';
  protected tags: Tag[] = [];
  protected timeFrame: string[] = [];
  public isBaseInfoValid = false;
  protected rules = {
    title: [this.requiredRule()],
    timeFrame: [this.requiredRule(), this.requiredRangeRule()],
  };
  showCalendar = false;
  locale = i18n.i18next.language;
  hasEditCampaignValuesBeenFilled = false;
  showForm = false;

  @Watch('isBaseInfoValid')
  onValid(val: boolean) {
    this.$emit('isValid', val);
  }

  @Watch('campaign')
  onCampaignChange(newCampaign: Campaign) {
    if (!this.hasEditCampaignValuesBeenFilled) {
      this.fillValues(newCampaign);
      this.hasEditCampaignValuesBeenFilled = true;
    }
  }

  mounted() {
    this.showForm = true;
  }

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }

  get formatDateRange() {
    if (this.timeFrame[0] && this.timeFrame[1]) {
      return (
        this.getSortedDates()[0].toLocaleDateString(this.locale) +
        ' - ' +
        this.getSortedDates()[1].toLocaleDateString(this.locale)
      );
    }
    const time = this.timeFrame[0] || this.timeFrame[1];
    return time ? `${new Date(time)?.toLocaleDateString(this.locale)}` : null;
  }

  async fetchTags(search: string) {
    this.searchResults = await findTagsUseCase({ search, type: this.tagType }, this.currentUser);
  }

  fillValues(campaign: Campaign) {
    this.title = campaign.title;
    this.description = campaign.description;
    this.tags = campaign.tags;
    this.timeFrame = [campaign.startDate, campaign.endDate];
  }

  getCampaignBaseInfo(): Partial<unknown> {
    return {
      title: this.title,
      description: this.description,
      tags: this.tags,
      startDate: this.getSortedDates()[0],
      endDate: this.getSortedDates()[1],
    };
  }

  getSortedDates() {
    let start = new Date(this.timeFrame[0]);
    let end = new Date(this.timeFrame[1]);
    if (end < start) {
      [start, end] = [end, start];
    }
    return [start, end];
  }

  requiredRule(): (value: unknown) => boolean | string {
    return (value) => !!value || value === 0 || this.$t('shared.common.error-required-field');
  }

  requiredRangeRule(): (value: unknown) => boolean | string {
    return () => (this.timeFrame && this.timeFrame.length === 2) || this.$t('shared.common.error-date-range');
  }

  updateTags(tags: Tag[]) {
    this.tags = tags;
  }

  saveTranslations(tag: LanguageObject, index: number) {
    if (this.tags && this.tags[index]) {
      this.tags[index].value = tag;
    }
  }

  toggleCalendar() {
    this.showCalendar = !this.showCalendar;
  }

  onClickOutsideCalendar() {
    this.showCalendar = false;
  }

  include() {
    return [document.querySelector('.included')];
  }
}
