import { container } from 'tsyringe';
import { CampaignService } from '@/core/campaign/services/CampaignService';
import { CreateCampaignDTO } from '@/core/campaign/DTO/CreateCampaignDTO';
import type { Campaign } from '@/core/campaign/models/Campaign';
import type { CurrentUser } from '@/core/user/models/CurrentUser';

export const createCampaignUseCase = async (input: CreateCampaignDTO, user: CurrentUser): Promise<Campaign> => {
  const campaignService = container.resolve<CampaignService>('CampaignService');

  return await campaignService.createCampaign(input, user);
};
