





















import { Component, Prop, Vue } from 'vue-property-decorator';
import WithMenuTagChip from '@/ui/components/atoms/groupingItems/WithMenuTagChip.vue';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import TagAutocomplete from '@/ui/components/molecules/inputs/TagAutocomplete.vue';
import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';
import { TagType } from '@/core/tags/enums/TagType';
import type { Tag } from '@/core/tags/models/Tag';

@Component({
  name: 'SubjectList',
  components: { WithMenuTagChip, TagAutocomplete },
  computed: {},
})
export default class SubjectList extends Vue {
  @Prop() protected existingTags!: Tag[];
  protected tagType = TagType.SUBJECT;
  protected searchResults = [] as Tag[];
  public subjects = [] as Tag[];

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }

  async fetchSubjects(search: string) {
    this.searchResults = await findTagsUseCase(
      {
        search,
        type: this.tagType,
      },
      this.currentUser,
    );
  }

  updateSubjects(subjects: Tag[]) {
    this.subjects = subjects;
    this.$emit('updated-subjects', this.subjects);
  }

  saveTranslations(tag: LanguageObject, index: number) {
    if (this.subjects && this.subjects[index]) {
      this.subjects[index].value = tag;
    }
  }
}
