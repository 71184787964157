













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import getLogger from '@/shared/logger/logger';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('DeleteCampaignDialog');
@Component({
  name: 'DeleteCampaignDialog',
})
export default class DeleteCampaignDialog extends Vue {
  @Prop({ default: false }) showDialog?: boolean;

  private dialog = false;

  @Watch('showDialog')
  watchShowDialog(newVal: boolean) {
    this.dialog = newVal;
  }

  confirm() {
    this.$emit('confirm');
  }
  cancel() {
    this.$emit('cancel');
  }
}
