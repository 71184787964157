


















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithMenuTagChip from '@/ui/components/atoms/groupingItems/WithMenuTagChip.vue';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import { getEmptyLanguageObject, isEmptyOrDuplicate } from '@/ui/services/tags/tagService';
import getLogger from '@/shared/logger/logger';
import { cloneDeep as _cloneDeep, unionBy as _unionBy } from 'lodash';
import i18n from '@/i18n';
import { TagType } from '@/core/tags/enums/TagType';
import type { Tag } from '@/core/tags/models/Tag';
import { CreateTagDTO } from '@/core/tags/DTO/CreateTagDTO';
import { v4 as uuid } from 'uuid';
import { INPUT } from '@/ui/constant/input.const';

const LOG = getLogger('TagAutocomplete');

@Component({
  name: 'TagAutocomplete',
  components: { WithMenuTagChip },
  computed: {},
})
export default class TagAutocomplete extends Vue {
  @Prop() private existingTags!: Tag[];
  @Prop() private searchResults!: Tag[];
  @Prop() private tagType!: TagType;
  @Prop() protected label: undefined | string;
  @Prop() protected pastedTags: undefined | Tag[];
  private timeout = 0 as ReturnType<typeof setTimeout>;
  private tagSelection = [] as Tag[] | CreateTagDTO[];
  private search = '';
  private locale = i18n.i18next.language as keyof LanguageObject;
  private minSearchLength = INPUT.MIN_SUGGESTION_STRING_LENGTH;
  private openAutoComplete = false;

  mounted() {
    this.tagSelection = _cloneDeep(this.existingTags);
  }

  @Watch('pastedTags')
  onPaste(val: Tag[]) {
    if (!val) {
      return;
    }
    this.tagSelection = _unionBy(this.tagSelection as Tag[], val, 'id');
  }

  @Watch('existingTags')
  onTags(val: Tag[]) {
    if (!val) {
      return;
    }
    this.tagSelection = _cloneDeep(this.existingTags);
  }

  @Watch('search')
  onSearch(val: string) {
    if (!val || val.length < this.minSearchLength) {
      return;
    }
    this.fetchEntriesDebounced();
  }

  get hasSearchResults() {
    return this.search.length >= this.minSearchLength && this.searchResults?.length > 0;
  }

  openAutoCompleteMenu() {
    this.openAutoComplete = true;
  }

  fetchEntriesDebounced() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.fetch();
    }, 500);
  }

  fetch() {
    if (this.search?.length >= this.minSearchLength) {
      this.$emit('fetched', this.search);
    }
  }

  updateParent() {
    this.$emit('updated', this.tagSelection);
  }

  saveTagTranslations(value: LanguageObject, index: number) {
    this.tagSelection[index].value = value;
    this.$emit('saveTranslations', value, index);
  }

  addTag() {
    const newTag: CreateTagDTO = { type: this.tagType, value: getEmptyLanguageObject(), id: uuid(), toBeCreated: true };
    newTag.value[this.locale] = this.search;
    const isExisting = this.searchResults.find((item) => this.search == this.$tagValue(item));
    if (!isEmptyOrDuplicate(newTag, this.tagSelection as Tag[], this.locale) && !isExisting) {
      this.tagSelection.push(newTag as Tag);
      this.search = '';
      this.updateParent();
    } else {
      LOG.warn('TODO: Error, duplicate or empty label');
    }
    this.openAutoCompleteMenu();
  }

  addSelectedItem(item: Tag) {
    const isNotDuplicate = !(this.tagSelection as Tag[]).find((newItem: Tag) => newItem.id === item.id);
    if (isNotDuplicate) {
      this.tagSelection.push(item);
      this.search = '';
      this.updateParent();
    }
  }

  removeTag(index: number) {
    this.tagSelection.splice(index, 1);
    this.updateParent();
  }
}
