
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import WithMenuTagChip from '@/ui/components/atoms/groupingItems/WithMenuTagChip.vue';
import TagAutocomplete from '@/ui/components/molecules/inputs/TagAutocomplete.vue';
import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';
import i18n from '@/i18n';

import { cloneDeep as _cloneDeep, unionBy as _unionBy } from 'lodash';
import { TagType } from '@/core/tags/enums/TagType';
import type { Tag } from '@/core/tags/models/Tag';

@Component({
  name: 'ModalEditAdditionalLabels',
  components: {
    ButtonWithText,
    WithMenuTagChip,
    TagAutocomplete,
  },
})
export default class ModalEditAdditionalLabels extends Vue {
  @Prop({ default: null }) predefinedTags!: {
    additionalLabels: Tag[];
    id: string;
  };
  @Prop({ default: null }) tagCache!: Tag[];
  private locale: keyof LanguageObject = i18n.i18next.language;
  protected tagType = TagType.GENERAL;
  protected searchResults = [] as Tag[];
  protected tags = [] as Tag[];
  private additionalLabelInput = '';
  private copiedTags: Tag[] = this.tagCache;
  private pastedTags = [] as Tag[];
  private modalEditAdditionalLabels = {
    valid: false,
    fields: {
      additionalLabels: [] as Tag[],
      id: '' as string,
    },
    rules: {
      additionalLabels: [],
    },
  };

  mounted() {
    this.modalEditAdditionalLabels.fields = _cloneDeep(this.predefinedTags);
  }

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }

  async fetchTags(search: string) {
    this.searchResults = await findTagsUseCase({ search, type: this.tagType }, this.currentUser);
  }

  removeLabel(index: number) {
    this.modalEditAdditionalLabels.fields.additionalLabels.splice(index, 1);
  }

  saveTranslations(tag: LanguageObject, index: number) {
    this.modalEditAdditionalLabels.fields.additionalLabels[index].value = tag;
  }

  pasteLabels() {
    this.pastedTags = _cloneDeep(this.copiedTags);
    this.modalEditAdditionalLabels.fields.additionalLabels = _unionBy(
      this.modalEditAdditionalLabels.fields.additionalLabels,
      this.pastedTags,
      'id',
    );
  }

  copyLabels() {
    this.copiedTags = _cloneDeep(this.modalEditAdditionalLabels.fields.additionalLabels);
    this.$emit('copyLabels', this.copiedTags);
  }

  addLabel(tags: Tag[]) {
    this.modalEditAdditionalLabels.fields.additionalLabels = _cloneDeep(tags);
  }

  emitAbortClicked() {
    this.modalEditAdditionalLabels.fields = this.predefinedTags;
    this.$emit('abortClicked');
  }

  emitSaveClicked() {
    this.$emit('saveClicked', this.modalEditAdditionalLabels.fields);
  }
}
