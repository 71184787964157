import { CreateCampaignMediumResponseDTO } from '@/infrastructure/models/Response';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { AxiosResponse } from 'axios';

export function cleanFilename(file: File): File {
  const blob = file.slice(0, file.size, file.type);

  let filename = file.name;
  filename = filename.replace(/[/\\?%*:|"<>!]/g, '-').replace(/[\s]/g, '_');

  return new File([blob], filename, { type: file.type });
}

export interface MediaService {
  createMediaFileUploadLink(
    file: File,
    user: CurrentUser,
    campaignId: string,
  ): Promise<CreateCampaignMediumResponseDTO>;

  uploadMedium(endpoint: string, file: File): Promise<void>;

  getMediumBlob(source: string): Promise<AxiosResponse<Blob>>;
}
