import { container } from 'tsyringe';
import { CampaignService } from '@/core/campaign/services/CampaignService';
import type { Campaign } from '@/core/campaign/models/Campaign';
import type { CurrentUser } from '@/core/user/models/CurrentUser';

export const updateCampaignUseCase = async (
  data: Partial<Campaign>,
  user: CurrentUser,
  campaignId: string,
): Promise<void> => {
  const campaignService = container.resolve<CampaignService>('CampaignService');

  await campaignService.updateCampaign(data, user, campaignId);
};
