import { container } from 'tsyringe';
import { MediaService } from '@/core/media/services/MediaService';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { CreateCampaignMediumResponseDTO } from '@/infrastructure/models/Response';
import { cleanFilename } from '@/core/media/services/MediaService';

export const uploadMediumUseCase = async (
  file: File,
  user: CurrentUser,
  campaignId: string,
): Promise<CreateCampaignMediumResponseDTO> => {
  const mediaService = container.resolve<MediaService>('MediaService');

  const cleanFile = cleanFilename(file);

  const upload = await mediaService.createMediaFileUploadLink(cleanFile, user, campaignId);
  if (upload) {
    await mediaService.uploadMedium(upload.uploadLink, cleanFile);
  }
  return upload;
};
