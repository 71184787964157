import { container } from 'tsyringe';
import { CampaignService } from '@/core/campaign/services/CampaignService';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';

export const updateCampaignMediumUseCase = async (
  data: CampaignMedia,
  user: CurrentUser,
  campaignId: string,
): Promise<void> => {
  const campaignService = container.resolve<CampaignService>('CampaignService');
  await campaignService.updateCampaignMedium(data, user, campaignId);
};
