
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { uploadMediumUseCase } from '@/core/media/usecases/uploadMediumUseCase';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import getLogger from '@/shared/logger/logger';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';
import { Loader } from '@/ui/util/decorators/loader';

const LOG = getLogger('FileDrop');

@Component({
  name: 'FileDrop',
})
export default class FileDrop extends Vue {
  @Prop() createdCampaignId!: string;
  @Prop() currentUser!: CurrentUser;
  protected areaIsHighlighted = false;
  private createdMedias: CampaignMedia[] = [];

  async selectFiles(files: File[]) {
    this.checkFileNames(files);
    this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
      message: 'campaign.campaign-notifications.uploding-media',
      type: AlertType.NOT_SET,
    });
    await this.mapFiles(files);
    this.$emit('files-selected', this.createdMedias);
    this.resetDragArea();
  }

  @Loader
  async uploadFiles(file: File): Promise<void> {
    if (this.createdCampaignId) {
      const { medium } = { ...(await uploadMediumUseCase(file, this.currentUser, this.createdCampaignId)) };
      this.createdMedias.push(medium);
      this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
        message: `${this.$t('campaign.campaign-notifications.media-success')} ${medium.fileName}`,
        type: AlertType.SUCCESS,
      });
    } else {
      throw new Error(`Could not set upload Link for ${file}`);
    }
  }

  async mapFiles(files: File[]) {
    try {
      for (const file of files) {
        await this.uploadFiles(file);
      }
    } catch (e) {
      LOG.error(e);
      this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
        message: this.$t(`campaign.campaign-notifications.uploading-media-server-error`),
        type: AlertType.ERROR,
      });
    }
  }

  checkFileNames(files: File[]) {
    let invalidNames = '';
    const re = /^[a-zA-Z0-9.\-_ ]+$/gm;

    for (const file of files) {
      if (!file.name.match(re)) {
        invalidNames = invalidNames.concat(file.name + '\n');
      }
    }

    const message = `${this.$t(
      'campaign.campaign-notifications.uploading-media-naming-error',
    )}\n${invalidNames}${this.$t('campaign.campaign-notifications.uploading-media-naming-part2-error')}`;

    if (invalidNames.length !== 0) {
      this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
        message: message,
        isCustomMessage: true,
        type: AlertType.ERROR,
      });
      throw new Error(`Creating upload links is not possible for the names of files: ${invalidNames}`);
    }
  }

  highlightDragArea() {
    this.areaIsHighlighted = true;
  }

  resetDragArea() {
    this.areaIsHighlighted = false;
  }
}
