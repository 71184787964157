

































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import type { LanguageObject } from '@/core/campaign/models/LanguageObject';
import i18n from '@/i18n';
import { getEmptyLanguageObject } from '@/ui/services/tags/tagService';
import { cloneDeep as _cloneDeep } from 'lodash';
import getLogger from '@/shared/logger/logger';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LOG = getLogger('WithMenuTagChip');

@Component({
  name: 'WithMenuTagChip',
})
export default class WithMenuTagChip extends Vue {
  @Prop({ default: null }) labelText?: string;
  @Prop({ default: null }) labelValue!: LanguageObject;
  @Prop({ default: null }) index!: number;
  @Prop({ default: false }) isFromDb?: boolean;
  protected showMenu = false;
  isActive = false;
  private modalEditLabel = {
    valid: false,
    fields: getEmptyLanguageObject(),
    rules: {
      'de-CH': [this.requiredRule('de-CH')],
      'fr-CH': [this.requiredRule('fr-CH')],
      'it-CH': [this.requiredRule('it-CH')],
      'en-CH': [this.requiredRule('en-CH')],
    },
  };

  mounted() {
    this.modalEditLabel.fields = _cloneDeep(this.labelValue);
  }

  requiredRule(language: string): (value: unknown) => boolean | string {
    if (language === i18n.i18next.language) {
      return (value) => !!value || value === 0 || this.$t('shared.common.error-required-field');
    }
    return () => true || '';
  }

  discardUnsavedChanges() {
    this.modalEditLabel.fields = _cloneDeep(this.labelValue);
  }

  handleAbortClicked() {
    this.modalEditLabel.fields = { ...this.labelValue };
    this.showMenu = false;
  }

  handleSaveClicked() {
    this.showMenu = false;
    this.$emit('saveTranslations', this.modalEditLabel.fields, this.index);
  }

  emitRemovedClick() {
    this.$emit('removeClick', this.index);
  }
}
