var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.showEditLabels)?_c('modal-edit-labels',{attrs:{"tagCache":_vm.tagCache,"predefinedTags":_vm.selectedSingleItem},on:{"saveClicked":_vm.saveEditLabelModalInfo,"abortClicked":function($event){return _vm.abortAdditionalLabelsModal()},"copyLabels":_vm.storeCopiedLabels}}):_vm._e(),_c('v-row',{staticClass:"table-wrapper"},[_c('v-data-table',{staticClass:"jls-data-table",attrs:{"disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.filterDeleted(_vm.mediaFiles),"page":_vm.page,"hide-default-footer":"","items-per-page":10},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header.fileName",fn:function(ref){
var header = ref.header;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")])]}},{key:"header.channel",fn:function(ref){
var header = ref.header;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")])]}},{key:"header.zone",fn:function(ref){
var header = ref.header;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")])]}},{key:"header.format",fn:function(ref){
var header = ref.header;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")])]}},{key:"header.language",fn:function(ref){
var header = ref.header;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")])]}},{key:"header.subject",fn:function(ref){
var header = ref.header;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")])]}},{key:"header.labels",fn:function(ref){
var header = ref.header;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"icons-wrapper"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"accent"},on:{"click":function($event){return _vm.editSingleItemLabels(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('shared.common.tooltip-edit-additionallabels')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"accent"},on:{"click":function($event){return _vm.removeMedia(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('shared.common.tooltip-delete')))])])],1)]}},{key:"item.fileName",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"rowitem",attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(item.fileName)+" ")])],1)]}},{key:"item.channel",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"rowitem",attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(_vm.getChannelList(item.zones))+" ")])],1)]}},{key:"item.labels",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"rowitem",attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(_vm.getLabelList(item.tags))+" ")])],1)]}},{key:"item.zone",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"rowitem",attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(_vm.getLabelList(item.zones))+" ")]),_c('v-menu',{attrs:{"offset-y":"","left":"","tile":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-chevron-down ")])]}}],null,true)},[_c('v-treeview',{staticClass:"menu",attrs:{"selectable":"","open-all":"","items":_vm.zoneItemsAsTree,"return-object":""},on:{"input":function($event){return _vm.updateZones(item.zones, item.id)}},model:{value:(item.zones),callback:function ($$v) {_vm.$set(item, "zones", $$v)},expression:"item.zones"}})],1)],1)]}},{key:"item.language",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"rowitem",attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(_vm.$tagValue(item.language))+" ")]),_c('v-menu',{attrs:{"offset-y":"","left":"","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-chevron-down ")])]}}],null,true)},[_c('v-radio-group',{staticClass:"menu",attrs:{"value":_vm.getCurrentLanguageId(item),"hide-details":""},on:{"change":function($event){return _vm.updateLanguage($event, item)}}},_vm._l((_vm.languageItems),function(language,index){return _c('v-radio',{key:("language" + index),attrs:{"label":_vm.$tagValue(language),"value":language.id,"color":"accent"}})}),1)],1)],1)]}},{key:"item.format",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"rowitem",attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(_vm.$tagValue(item.format))+" ")]),_c('v-menu',{attrs:{"offset-y":"","left":"","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-chevron-down ")])]}}],null,true)},[_c('v-radio-group',{staticClass:"menu",attrs:{"value":_vm.getCurrentFormatId(item),"hide-details":""},on:{"change":function($event){return _vm.updateFormat($event, item)}}},_vm._l((_vm.formatItems),function(format,index){return _c('v-radio',{key:("format" + index),attrs:{"label":_vm.$tagValue(format),"value":format.id,"color":"accent"}})}),1)],1)],1)]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"rowitem",attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(_vm.$tagValue(item.subject))+" ")]),_c('v-menu',{attrs:{"offset-y":"","left":"","tile":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-chevron-down ")])]}}],null,true)},[_c('v-radio-group',{staticClass:"menu",attrs:{"value":_vm.getCurrentSubjectId(item),"hide-details":""},on:{"change":function($event){return _vm.updateSubject($event, item)}}},_vm._l((_vm.subjectItems),function(subject,index){return _c('v-radio',{key:("subject" + index),attrs:{"label":_vm.$tagValue(subject),"value":subject.id,"color":"accent"}})}),1)],1)],1)]}}],null,true)}),_c('div',{staticClass:"pagination-wrapper"},[_c('v-pagination',{staticClass:"jls-data-table-footer",attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('FileDrop',{attrs:{"current-user":_vm.currentUser,"created-campaign-id":_vm.campaignId},on:{"files-selected":_vm.addFiles}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }