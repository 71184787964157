








































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import FileDrop from '@/ui/components/atoms/FileDrop.vue';
import ModalEditLabels from '@/ui/components/molecules/modals/ModalEditAdditionalLabels.vue';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import getLogger from '@/shared/logger/logger';
import type { Campaign } from '@/core/campaign/models/Campaign';
import type { CurrentUser } from '@/core/user/models/CurrentUser';
import { findTagsUseCase } from '@/core/tags/usecases/findTagsUseCase';
import { MediumStatus } from '@/core/campaign/enums/Campaign';
import { TagType } from '@/core/tags/enums/TagType';
import type { Tag } from '@/core/tags/models/Tag';

interface TreeElementType {
  id?: string;
  name: string;
  value: Tag;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = getLogger('CampaignMediaUpload');

@Component({
  name: 'CampaignMediaUpload',
  components: {
    FileDrop,
    ModalEditLabels,
  },
})
export default class CampaignMediaUpload extends Vue {
  @Prop() campaign!: Campaign;
  @Prop() currentUser!: CurrentUser;
  private mediaFiles: CampaignMedia[] = [];
  private showEditLabels = false;
  protected tagCache: Tag[] = [];
  protected selectedSingleItem!: {
    additionalLabels: Tag[];
    id: string;
  };
  protected languageItems = [] as Tag[];
  protected zoneItems = [] as Tag[];
  protected formatItems = [] as Tag[];

  page = 1;
  pageCount = 0;

  async mounted() {
    this.formatItems = await findTagsUseCase({ type: TagType.FORMAT, isActive: true }, this.currentUser);
    this.languageItems = await findTagsUseCase({ type: TagType.LANGUAGE, isActive: true }, this.currentUser);
    this.zoneItems = await findTagsUseCase({ type: TagType.ZONE, isActive: true }, this.currentUser);
    this.mediaFiles = this.campaign.media;
    this.selectedSingleItem = {
      additionalLabels: [],
      id: '',
    };
  }

  get headers() {
    return [
      { text: 'campaign.new-campaign.table-mediaupload-name', value: 'fileName' },
      { text: 'campaign.new-campaign.table-mediaupload-channel', value: 'channel' },
      { text: 'campaign.new-campaign.table-mediaupload-zone', value: 'zone' },
      { text: 'campaign.new-campaign.table-mediaupload-format', value: 'format' },
      { text: 'campaign.new-campaign.table-mediaupload-language', value: 'language' },
      { text: 'campaign.new-campaign.table-mediaupload-subject', value: 'subject' },
      { text: 'campaign.new-campaign.table-mediaupload-labels', value: 'labels' },
      { text: '', value: 'actions' },
    ];
  }

  get subjectItems() {
    return this.campaign?.subjects;
  }

  get zoneItemsAsTree() {
    return this.getTreeview(this.zoneItems);
  }

  get campaignId() {
    return this.campaign?.id;
  }

  get defaultFormatItems() {
    return this.formatItems.filter((tag: Tag) => tag.isDefault);
  }

  get defaultLanguageItems() {
    return this.languageItems.filter((tag: Tag) => tag.isDefault);
  }

  get defaultZoneItems() {
    return this.zoneItems.filter((tag: Tag) => tag.isDefault);
  }

  updateCampaignMedia(media: CampaignMedia[]) {
    this.$emit('update-campaign-media', media);
  }

  filterDeleted(media: CampaignMedia[]): CampaignMedia[] {
    return media.filter((item) => item.status !== MediumStatus.DELETED);
  }

  getTreeview(zones: Tag[]) {
    const tree: { id: string; name: string; children: TreeElementType[] }[] = [];
    zones?.forEach((item) => {
      const currentGroupName = item.group ? this.$tagValue(item.group) : '';
      const index = tree.findIndex((element) => element.name == currentGroupName);
      if (index >= 0) {
        const newChild = {
          id: item.id,
          name: this.$tagValue(item),
          value: item,
        };
        tree[index].children.push(newChild);
      } else {
        const newChild = {
          id: item.id,
          name: this.$tagValue(item),
          value: item,
        };
        const newTreeElement = {
          id: item.id + '_parent',
          name: item.group ? this.$tagValue(item.group) : '',
          children: [newChild],
        };
        tree.push(newTreeElement);
      }
    });
    return tree;
  }

  getZonesFromTree(tree: TreeElementType[]) {
    const reconstructedZones: Tag[] = [];
    tree.forEach((item) => {
      reconstructedZones.push(item.value);
    });
    return reconstructedZones;
  }

  updateZones(zones: TreeElementType[], id: string) {
    const mediaIndex = this.mediaFiles.findIndex((media) => media.id === id);
    if (mediaIndex > -1) {
      this.mediaFiles[mediaIndex].zones = this.getZonesFromTree(zones);
      this.updateCampaignMedia(this.mediaFiles);
    }
  }

  getChannelList(zones: Tag[]) {
    const groups: string[] = [];
    zones?.forEach((zone) => {
      if (zone && zone.group) {
        const groupValue = this.$tagValue(zone.group);
        if (!groups.find((item) => item == groupValue)) {
          groups.push(this.$tagValue(zone.group));
        }
      }
    });
    return groups.join(', ');
  }

  removeMedia(element: CampaignMedia) {
    const item = this.mediaFiles.find((el) => el.id === element.id);
    let index = -1;
    if (item) {
      index = this.mediaFiles.indexOf(item);
    }
    if (index > -1) {
      this.mediaFiles[index].status = MediumStatus.DELETED;
    }
    this.updateCampaignMedia(this.mediaFiles);
  }

  storeCopiedLabels(tags: Tag[]) {
    this.tagCache = tags;
  }

  addFiles(medias: CampaignMedia[]) {
    medias.forEach((medium) => {
      const found = this.mediaFiles.some((file) => file.id === medium.id);
      if (!found) {
        if (this.defaultLanguageItems?.length > 0) {
          if (!medium.language) {
            Object.assign(medium, { language: {} });
          }
          Object.assign(medium.language, { ...this.defaultLanguageItems[0] });
        }
        if (this.defaultFormatItems?.length > 0) {
          if (!medium.format) {
            Object.assign(medium, { format: {} });
          }
          Object.assign(medium.format, { ...this.defaultFormatItems[0] });
        }
        if (this.defaultZoneItems?.length > 0) {
          if (!medium.zones) {
            medium.zones = [];
          }
          medium.zones = [...this.defaultZoneItems];
        }
        this.mediaFiles.push(medium);
      }
    });
    this.mediaFiles = this.sortMediaFiles(this.mediaFiles);
    this.updateCampaignMedia(this.mediaFiles);
  }

  getLabelList(labels: Tag[]) {
    const values: string[] = [];
    labels?.forEach((label) => {
      if (label) {
        values.push(this.$tagValue(label));
      }
    });
    return values.join(', ');
  }

  sortMediaFiles(files: CampaignMedia[]) {
    function compare(a: CampaignMedia, b: CampaignMedia) {
      if (a.fileName < b.fileName) {
        return -1;
      }
      if (a.fileName > b.fileName) {
        return 1;
      }
      return 0;
    }
    return files?.sort(compare);
  }

  abortAdditionalLabelsModal() {
    this.showEditLabels = false;
  }

  editSingleItemLabels(element: CampaignMedia) {
    this.selectedSingleItem = {
      additionalLabels: [...element.tags],
      id: element.id,
    };
    this.showEditLabels = true;
  }

  saveEditLabelModalInfo(fields: { additionalLabels: Tag[]; id: string }) {
    const index = this.mediaFiles.findIndex((el) => el.id === fields.id);
    this.mediaFiles[index].tags = fields.additionalLabels;
    this.updateCampaignMedia(this.mediaFiles);
    this.selectedSingleItem = {
      additionalLabels: [],
      id: '',
    };
    this.showEditLabels = !this.showEditLabels;
  }

  updateLanguage(newId: string, item: CampaignMedia) {
    const newLanguage = this.languageItems.find((language: Tag) => language.id === newId);
    const itemIndex = this.mediaFiles.indexOf(item);
    if (newLanguage) {
      item.language = newLanguage;
      let editedItem = { ...this.mediaFiles[itemIndex], ...item };
      this.mediaFiles.splice(itemIndex, 1, editedItem);
    }
  }

  updateFormat(newId: string, item: CampaignMedia) {
    const newFormat = this.formatItems.find((format: Tag) => format.id === newId);
    const itemIndex = this.mediaFiles.indexOf(item);
    if (newFormat) {
      item.format = newFormat;
      let editedItem = { ...this.mediaFiles[itemIndex], ...item };
      this.mediaFiles.splice(itemIndex, 1, editedItem);
    }
  }

  updateSubject(newId: string, item: CampaignMedia) {
    const newSubject = this.subjectItems.find((subject: Tag) => subject.id === newId);
    const itemIndex = this.mediaFiles.indexOf(item);
    if (newSubject) {
      item.subject = newSubject;
      let editedItem = { ...this.mediaFiles[itemIndex], ...item };
      this.mediaFiles.splice(itemIndex, 1, editedItem);
    }
  }

  getCurrentSubjectId(item: CampaignMedia) {
    return item.subject?.id;
  }

  getCurrentFormatId(item: CampaignMedia) {
    return item.format?.id;
  }

  getCurrentLanguageId(item: CampaignMedia) {
    return item.language?.id;
  }
}
