












































































































import { Component, Mixins, Ref } from 'vue-property-decorator';
import ButtonWithText from '@/ui/components/atoms/buttons/ButtonWithText.vue';
import SubjectList from '@/ui/components/molecules/inputs/SubjectList.vue';
import CampaignBaseInfo from '@/ui/components/organisms/campaigns/CampaignBaseInfo.vue';
import CampaignMediaUpload from '@/ui/components/molecules/tables/CampaignMediaUpload.vue';
import getLogger from '@/shared/logger/logger';
import { AdminStoreGetters, AdminStoreMutations } from '@/ui/stores/AdminStore';
import { NavigationMixin } from '@/ui/mixins/NavigationMixin';
import { createCampaignUseCase } from '@/core/campaign/usecases/createCampaignUseCase';
import { updateCampaignUseCase } from '@/core/campaign/usecases/updateCampaignUseCase';
import { deleteCampaignUseCase } from '@/core/campaign/usecases/deleteCampaignUseCase';
import { updateCampaignMediumUseCase } from '@/core/campaign/usecases/updateCampaignMediumUseCase';
import { CreateCampaignDTO } from '@/core/campaign/DTO/CreateCampaignDTO';
import type { Campaign } from '@/core/campaign/models/Campaign';
import { CampaignMedia } from '@/core/campaign/models/CampaignMedia';
import { MediumStatus } from '@/core/campaign/enums/Campaign';
import type { Tag } from '@/core/tags/models/Tag';
import DeleteCampaignDialog from '@/ui/components/organisms/dialogs/DeleteCampaignDialog.vue';
import { CampaignStoreActions, CampaignStoreMutations } from '@/ui/stores/CampaignStore';
import { AlertStoreActions, AlertType } from '@/ui/stores/AlertStore';
import { ROUTE_NAMES } from '@/ui/constant/routes.constants';
import { Loader } from '@/ui/util/decorators/loader';

const LOG = getLogger('EditCampaign');

@Component({
  name: 'EditCampaign',
  components: {
    SubjectList,
    ButtonWithText,
    CampaignMediaUpload,
    CampaignBaseInfo,
    DeleteCampaignDialog,
  },
})
export default class EditCampaign extends Mixins(NavigationMixin) {
  @Ref('CampaignBaseInfo') campaignBaseInfo!: CampaignBaseInfo;
  @Ref('CampaignSubjectsList') campaignSubjectsList!: SubjectList;
  private activeTabNumber = 0;
  private campaign: Campaign | null = null;
  protected isBaseInfoValid = false;
  private showDeleteCampaign = false;
  private isLoading = false;

  mounted() {
    if (this.$store.getters[AdminStoreGetters.GET_IS_CAMPAIGN_EDIT] && this.$route.name === ROUTE_NAMES.EDIT_CAMPAIGN) {
      this.campaign = this.$store.getters[AdminStoreGetters.GET_CAMPAIGN_TO_EDIT];
    }
  }

  get lastSavedParentRouteForEditCampaign() {
    return this.$store.state.permanentStore.lastSavedParentRouteForEditCampaign;
  }

  get disableContinueButton() {
    if (this.activeTabNumber === 0) {
      return !this.isBaseInfoValid || this.isLoading;
    } else if (this.activeTabNumber === 1) {
      return this.isLoading;
    } else if (this.activeTabNumber === 2) {
      const activeFiles = this.campaign
        ? this.campaign.media.filter((item) => item.status != MediumStatus.DELETED)
        : [];
      return activeFiles.length == 0;
    } else return false;
  }

  get currentUser() {
    return this.$store.state.userStore.currentUser;
  }

  get isRoutedFromAdmin() {
    return this.$store.getters[AdminStoreGetters.GET_IS_ADMIN_CREATE_CAMPAIGN];
  }

  get campaignSubjects() {
    return this.campaign?.subjects;
  }

  get finishButtonText() {
    if (this.isEditCampaign) {
      return 'campaign.campaign-edit.title';
    } else {
      return 'shared.common.button-complete';
    }
  }

  get buttonText() {
    if (this.activeTabNumber == 2) {
      return this.finishButtonText;
    }
    return 'shared.common.button-continue';
  }

  get hasSubject() {
    return this.campaign && this.campaign?.subjects?.length > 0;
  }

  get isEditCampaign() {
    return this.$store.getters[AdminStoreGetters.GET_IS_CAMPAIGN_EDIT];
  }

  get isCreateCampaign() {
    return !this.$store.getters[AdminStoreGetters.GET_IS_CAMPAIGN_EDIT];
  }

  get getTitle(): string {
    if (this.isEditCampaign) {
      return 'campaign.campaign-edit.title';
    } else {
      return 'campaign.new-campaign.title';
    }
  }

  get showBackToAdmin(): boolean {
    return (this.isEditCampaign && this.isRoutedFromAdmin) || (this.isRoutedFromAdmin && this.activeTabNumber === 0);
  }

  get showBackToCampaign(): boolean {
    return this.isEditCampaign && !this.isRoutedFromAdmin;
  }

  async updateCampaignMedia(media: CampaignMedia[]) {
    if (this.campaign) {
      this.campaign.media = media;
    }
  }

  async updateSubjects(subjects: Tag[]) {
    if (this.campaign) {
      this.campaign.subjects = subjects;
    }
  }

  @Loader
  async updateTabNumber(number?: number) {
    try {
      this.isLoading = true;
      await this.handleTabUpdate();
      this.activeTabNumber = number ?? this.activeTabNumber + 1;
      if (this.activeTabNumber > 2) {
        this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
          message: 'campaign.campaign-notifications.processing-update',
          type: AlertType.NOT_SET,
        });
        this.findNextView();
      }
    } catch (e) {
      LOG.error(`Could not create or update campaign: ${e}`);
    } finally {
      this.isLoading = false;
    }
  }

  async handleTabUpdate() {
    if (this.activeTabNumber === 0) {
      await this.handleCampaignBaseInformation();
    }
    if (this.activeTabNumber === 1) {
      await this.handleCampaignSubjects();
    }
    if (this.activeTabNumber === 2) {
      await this.handleCampaignMedia();
    }
  }

  async handleCampaignBaseInformation(): Promise<void> {
    const campaignBaseInfoDTO: Partial<Campaign> = this.campaignBaseInfo.getCampaignBaseInfo();
    if (campaignBaseInfoDTO) {
      if (!this.campaign) {
        this.campaign = await createCampaignUseCase(campaignBaseInfoDTO as CreateCampaignDTO, this.currentUser);
      } else {
        await updateCampaignUseCase(campaignBaseInfoDTO, this.currentUser, this.campaign.id);
        this.campaign = { ...this.campaign, ...campaignBaseInfoDTO };
      }
    } else {
      throw new Error('no base info received');
    }
  }

  async handleCampaignSubjects() {
    if (this.campaign?.id) {
      const subjects: Partial<Campaign> = { subjects: this.campaign.subjects };
      await updateCampaignUseCase(subjects, this.currentUser, this.campaign.id);
      this.campaign = { ...this.campaign, ...subjects };
    }
  }

  async handleCampaignMedia() {
    if (this.campaign && this.campaign.media.length > 0) {
      return Promise.all(this.campaign.media.map(async (file) => await this.updateCampaignMedium(file)));
    }
  }

  async updateCampaignMedium(file: CampaignMedia) {
    if (this.campaign) {
      await updateCampaignMediumUseCase(file, this.currentUser, this.campaign.id);
    }
  }

  async deleteCampaign(): Promise<void> {
    if (this.campaign) {
      await deleteCampaignUseCase(this.currentUser, this.campaign.id);
      await this.$store.dispatch(CampaignStoreActions.TRIGGER_DELETE_CAMPAIGN, this.campaign.id);
      this.findNextViewAfterDelete();
    }
    this.hideDeleteCampaignMessage();
  }

  async findNextView() {
    this.$store.dispatch(CampaignStoreMutations.SET_CAMPAIGNS);
    this.$store.dispatch(AdminStoreMutations.SET_CAMPAIGNS);

    if (this.isCreateCampaign) {
      this.lastSavedParentRouteForEditCampaign && this.lastSavedParentRouteForEditCampaign === ROUTE_NAMES.CAMPAIGNS
        ? this.backToCampaigns()
        : this.backToAdminTab();
    } else {
      this.lastSavedParentRouteForEditCampaign &&
      this.lastSavedParentRouteForEditCampaign === ROUTE_NAMES.CAMPAIGN_DETAIL_MEDIA
        ? this.backToCampaignDetails()
        : this.backToAdminTab();
    }
  }

  async findNextViewAfterDelete() {
    this.$store.dispatch(AlertStoreActions.TRIGGER_ALERT, {
      message: 'campaign.campaign-notifications.deleting-campaign',
      type: AlertType.SUCCESS,
    });

    this.$store.dispatch(CampaignStoreMutations.SET_CAMPAIGNS);
    this.$store.dispatch(AdminStoreMutations.SET_CAMPAIGNS);

    this.lastSavedParentRouteForEditCampaign &&
    this.lastSavedParentRouteForEditCampaign === ROUTE_NAMES.CAMPAIGN_DETAIL_MEDIA
      ? this.backToCampaigns()
      : this.backToAdminTab();
  }

  isActive(num: number) {
    return this.activeTabNumber == num;
  }

  showDeleteCampaignMessage(): void {
    this.showDeleteCampaign = true;
  }

  hideDeleteCampaignMessage(): void {
    this.showDeleteCampaign = false;
  }

  validate(value: boolean) {
    this.isBaseInfoValid = value;
  }

  backToCampaigns() {
    this.$store.commit(AdminStoreMutations.RESET_CAMPAIGN_TO_EDIT);
    this.$router.push({
      name: ROUTE_NAMES.CAMPAIGNS,
    });
  }

  backToCampaignDetails() {
    this.$store.commit(AdminStoreMutations.RESET_CAMPAIGN_TO_EDIT);
    this.$router.push({
      name: this.$store.state.permanentStore.lastSavedParentRouteForEditCampaign,
      params: { campaignId: this.$store.state.permanentStore.lastSavedParentRouteParamsForEditCampaign.campaignId },
    });
  }

  backToAdminTab() {
    this.$store.commit(AdminStoreMutations.RESET_CAMPAIGN_TO_EDIT);
    this.$router.push({
      name: ROUTE_NAMES.ADMIN_CAMPAIGNS,
    });
  }

  handleGoBack() {
    this.activeTabNumber = this.activeTabNumber - 1;
  }
}
